/* eslint-disable no-restricted-imports */

import React, { useEffect } from "react";
import * as deviceRedux from "../_redux/devicesRedux";
import { getAllDeviceLogs } from '../_redux/devicesLogsCrud';
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

import RemotePagination from "../../../components/tables/RemotePagination";

const columns = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "CH4_Resit",
    text: "CH4",
  },
  {
    dataField: "CO_Resit",
    text: "CO",
  },
  {
    dataField: "THERM_Resit",
    text: "THERM",
  },
  {
    dataField: "Alarm",
    text: "Alarma",
  },
  {
    dataField: "CalPtCO1",
    text: "c_CO1",
  },
  {
    dataField: "CalPtCO2",
    text: "c_CO2",
  },
  {
    dataField: "CalPtCH4",
    text: "c_CH4",
  },
  {
    dataField: "SensorFault",
    text: "SF",
  },
  {
    dataField: "temperature",
    text: "TMP",
  },
  {
    dataField: "CH4_level",
    text: "Level_CH4",
  },
  {
    dataField: "CO_level",
    text: "Level_CO",
  },
  {
    dataField: "created_at",
    text: "Creado",
  },
];


function DevicesTable(props) {
  const SIZE_PER_PAGE = 10

  const {id:_deviceId} = useParams();
  const {deviceLogs:{ deviceLogList, totalDevices, pageNumber} } = useSelector((state)=>state.devices)

  const handleTableChange = (type, { page, sizePerPage }) => {
    setTimeout(() => {
      fetchDevices(page,sizePerPage);
    }, 2000);
  };

  const fetchDevices = async (page,sizePerPage) => {
    try {
      props.requestDeviceLogList()
      const rawDevicesResponse = await getAllDeviceLogs(_deviceId,page-1,sizePerPage)
      console.log(rawDevicesResponse)
      const {results,total} = rawDevicesResponse
      props.fulfillDeviceLogList(results, total, page)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchDevices(1,SIZE_PER_PAGE); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <RemotePagination
      columns={columns}
      data={deviceLogList}
      page={pageNumber}
      sizePerPage={SIZE_PER_PAGE}
      totalSize={totalDevices}
      onTableChange={handleTableChange}
    />
  );
}

export default injectIntl(connect(null, deviceRedux.actions)(DevicesTable));
