import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";
import { getAllAdminUsers } from "./adminUsersCrud";

export const actionTypes = {
    AdminUserListRequested: "[Load User List] Request Get All ",
    AdminUserListLoaded: "[Loaded User List] Get All",
    AdminUserPostNewUser: "[Send new User] Post new user",
    AdminUserDeleteUser: "[Delete User] Logical delete user",
    AdminUserEditUser: "[Edit User] Edit",
};

const initialUserAdminState = {
  userList: [],
};

export const reducer = persistReducer(
  { storage, key: "v1-admin-users", whitelist: ["userList"] },
  (state = initialUserAdminState, action) => {
    switch (action.type) {

      case actionTypes.AdminUserListRequested: {
        console.log("Send  request admin user list ")
        return { ...state, userList:[] };
      }

      case actionTypes.AdminUserListLoaded: {
        const { userList } = action.payload;
        console.log("Loaded admin user list - Payload:",action.payload)
        return { ...state, userList };
      }

      case actionTypes.AdminUserPostNewUser: {
        return { ...state };
      }

      case actionTypes.AdminUserDeleteUser: {
        return { ...state };
      }

      case actionTypes.AdminUserEditUser: {
        return { ...state };
      }


      default:
        return state;
    }
  }
);

export const actions = {
  requestUserList: () => ({ type: actionTypes.AdminUserListRequested }),
  fulfillUserList: userList => ({ type: actionTypes.AdminUserListLoaded, payload: { userList } }),
  postNewAdminUser: () => ({ type: actionTypes.AdminUserPostNewUser }),
  deleteAdminUser: () => ({ type: actionTypes.AdminUserDeleteUser }),
  editAdminUser: () => ({type: actionTypes.AdminUserEditUser})
};

export function* saga() {
//   yield takeLatest(actionTypes.AdminUserPostNewUser, function* loginSaga() {
//     yield put(actions.requestUserList());
//   });

//   yield takeLatest(actionTypes.Register, function* registerSaga() {
    // yield put(actions.requestUser());
//   });

  yield takeLatest(actionTypes.AdminUserPostNewUser, function* userPosted() {
    const userList = yield getAllAdminUsers();

    yield put(actions.fulfillUserList(userList));
  });

  yield takeLatest(actionTypes.AdminUserDeleteUser, function* userDeleted() {
    const userList = yield getAllAdminUsers();

    yield put(actions.fulfillUserList(userList));
  });

  yield takeLatest(actionTypes.AdminUserEditUser, function* userEdited() {
    const userList = yield getAllAdminUsers();

    yield put(actions.fulfillUserList(userList));
  });
}
