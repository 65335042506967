import axios from "axios";

export const GET_ALL_CLIENT_USERS = "backoffice/clients/";

export function getAllClientUsers(page=0,pageSize) {
  return axios.get(GET_ALL_CLIENT_USERS, {
    params: {
      page,
      pageSize
    }
  }).then(response=>response.data);
}
