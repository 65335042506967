import axios from "axios";

export const GET_ALL_ADMIN_USERS = "backoffice/users/";
export const POST_NEW_ADMIN_USER = "backoffice/users/";
export const DELETE_ADMIN_USER = "backoffice/users/";
export const EDIT_ADMIN_USER = "backoffice/users/";

export function getAllAdminUsers() {
  return axios.get(GET_ALL_ADMIN_USERS).then(response=>response.data);
}
export function postNewAdminUsers(email,pass) {
  return axios.post(POST_NEW_ADMIN_USER, {email,pass}).then(response=>response.data);
}

export function deleteAdminUser(idUser) {
  return axios.delete(DELETE_ADMIN_USER + idUser).then(response=>response.data);
}

export function editAdminUser(idUser, data) {
  return axios.patch(EDIT_ADMIN_USER + idUser, data).then(response=>response.data);
}
