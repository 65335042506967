/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/admin-users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin-users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">Usuarios Administradores</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/client-users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/client-users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">Usuarios clientes</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/devices", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/devices">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Router2.svg")}/>
              </span>
              <span className="menu-text">Dispositivos</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Custom */}
          {/* begin::section */}
          {/* <li className="menu-section ">
            <h4 className="menu-text">Custom</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
          {/* end:: section */}

        {/* end::Menu Nav */}
        </ul>
      </>
  );
}
