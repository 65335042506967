import React from "react";
import {useSubheader} from "../../_metronic/layout";
import UsersTable from "../modules/AdminUser/UsersTable";
import AddUserForm from "../modules/AdminUser/AddUserForm";

export function AdminUsersPage() {
    
  const suhbeader = useSubheader();
  suhbeader.setTitle("Administrar usuarios administradores");
  

  return <>
    <AddUserForm/>
    <UsersTable/>
  </>;
}
