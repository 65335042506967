import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";
// import { getAllAdminUsers } from "./adminUsersCrud";

export const actionTypes = {
    ClientUserListRequested: "[Load Client List] Request Get All ",
    ClientUserListLoaded: "[Loaded Client List] Get All",
};

const initialUserAdminState = {
  clientList: [],
};

export const reducer = persistReducer(
  { storage, key: "v1-client-users", whitelist: ["clientList"] },
  (state = initialUserAdminState, action) => {
    switch (action.type) {

      case actionTypes.ClientUserListRequested: {
        console.log("Send request admin user list ")
        return { ...state, clientList:[] };
      }

      case actionTypes.ClientUserListLoaded: {
        const { clientList,totalClients,pageNumber } = action.payload;
        console.log("Loaded client user list - Payload:",action.payload)
        return { ...state, clientList, totalClients, pageNumber };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  requestClientList: () => ({ type: actionTypes.ClientUserListRequested }),
  fulfillClientList: (clientList,totalClients,pageNumber) => ({ type: actionTypes.ClientUserListLoaded, payload: { clientList,totalClients,pageNumber } }),
};

export function* saga() {

}
