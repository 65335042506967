import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import { useFormik } from "formik";
import { injectIntl } from "react-intl";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as userAdminRedux from "./_redux/adminUsersRedux";
import { postNewAdminUsers } from "./_redux/adminUsersCrud";
import { Typography } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    // overflowX: "auto",
  },
  container: {
    paddingTop: 50,
  }
}));

const initialValues = {
  email: "email@gmail.com",
  password: "",
};

function AddUserForm(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Formato de correo incorrecto")
      .min(3, "Mínimo 3 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Email requerido"),
    password: Yup.string()
      .min(6, "Mínimo 6 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Constraseña requerida"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus("");
      setTimeout(() => {
        postNewAdminUsers(values.email, values.password)
          .then((responseData) => {
            setLoading(false);
            NotificationManager.success(
              "El usuario se creo correctamente",
              "Usuario creado"
            );
            formik.setValues(initialValues);
            props.postNewAdminUser();
            setSubmitting(false);
          })
          .catch((e) => {
            console.error("Error creando usuario", e);
            setLoading(false);
            setSubmitting(false);
            setStatus(
              e.response?.data?.errors[0].msg || "Error creando el usuario"
            );
          });
      }, 1000);
    },
  });
  return (
    <Paper className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Typography variant="h5" >
          Agrega nuevo usuario administrador
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={1}>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <button
                  id="kt_new_user_submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`btn btn-primary font-weight-bold px-12 py-4 my-3`}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <span>Enviar</span>
                    </div>
                    <div className="col-sm-6">
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </div>
                  </div>
                </button>
              </div>
            </Grid>
            <Grid item xs={12}>
              {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </form>
        {/*end::Form*/}
      </Container>
    </Paper>
  );
}

export default injectIntl(connect(null, userAdminRedux.actions)(AddUserForm));
