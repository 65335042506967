import React from "react";
import {makeStyles, Paper} from "@material-ui/core";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    centered: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    rightDistance:{
      paddingRight: "10px"
    }
  }));
  

const RemotePagination = ({
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
    columns,
  }) => {
    const classes = useStyles();
    return (
      <Paper className={classes.root}>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField="id"
                data={data}
                columns={columns}
                onTableChange={onTableChange}
                {...paginationTableProps}
              />
              <div className={classes.centered}>
                <span className={classes.rightDistance}>Total: { paginationProps.totalSize } </span>
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          )}
        </PaginationProvider>
      </Paper>
    );
  };

export default RemotePagination