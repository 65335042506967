import React from "react";
import {useSubheader} from "../../_metronic/layout";
import DeviceTable from "../modules/Devices/DeviceTable";
import LogsPage from "../modules/Devices/logs/logsPage";

import { Switch} from "react-router-dom";
import {ContentRoute} from "../../_metronic/layout";

export function DevicesListPage() {
    
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dispositivos");
  
  return (
    <Switch>
      <ContentRoute
        exact={true}
        path="/devices" 
        component={
            DeviceTable
          } 
      />

      <ContentRoute
        exact={true}
        path="/devices/:id/logs" 
        component={
            LogsPage
          } 
      />
    </Switch>
  )
}
