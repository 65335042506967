import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    deviceListRequested: "[Load device List] Request Get All ",
    deviceListLoaded: "[Loaded device List] Get All",
    deviceLogListRequested: "[Load deviceLog List] Request Get ",
    deviceLogListLoaded: "[Loaded deviceLog List] Get",
};

const initialDevicesState = {
  deviceList: [],
  totalDevices: null,
  pageNumber: null,
  deviceLogs: {deviceId:null, deviceLogList:[]},
};

export const reducer = persistReducer(
  { storage, key: "v1-devices", whitelist: ["deviceList"] },
  (state = initialDevicesState, action) => {
    switch (action.type) {

      case actionTypes.deviceListRequested: {
        console.log("Send request device list ")
        return { ...state, deviceList:[] };
      }

      case actionTypes.deviceListLoaded: {
        const { deviceList,totalDevices,pageNumber } = action.payload;
        console.log("Loaded device list - Payload:",action.payload)
        return { ...state, deviceList,totalDevices,pageNumber };
      }

      case actionTypes.deviceLogListRequested: {
        console.log("Send request device Log list ")
        return { ...state, deviceLogs:{...initialDevicesState.deviceLogs, ...action.payload} };
      }

      case actionTypes.deviceLogListLoaded: {
        const { deviceLogList, totalDevices, pageNumber } = action.payload;
        console.log("Loaded device list - Payload:",action.payload)
        return { ...state, deviceLogs:{ ...state.deviceLogs, deviceLogList, totalDevices, pageNumber} };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  requestDeviceList: () => ({ type: actionTypes.deviceListRequested }),
  fulfillDeviceList: (deviceList,totalDevices,pageNumber) => ({ type: actionTypes.deviceListLoaded, payload: { deviceList,totalDevices,pageNumber } }),
  requestDeviceLogList: deviceId => ({ type: actionTypes.deviceLogListRequested, payload: { deviceId } }),
  fulfillDeviceLogList: (deviceLogList,totalDevices,pageNumber) => ({ type: actionTypes.deviceLogListLoaded, payload: { deviceLogList, totalDevices, pageNumber} }),
};

export function* saga() {

}
