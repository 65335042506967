import React, { useState } from "react";

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";

const TableRowSimple = ({ userRow, handleDelete, setEditMode }) => {
  return (
    <TableRow key={userRow.id}>
      <TableCell component="th" scope="row">
        {userRow.email}
      </TableCell>
      <TableCell align="right">
        *******
      </TableCell>
      <TableCell align="right">
        {userRow.isRemoved ? "ELIMINADO" : "activo"}
      </TableCell>
      <TableCell align="right">{userRow.id}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Delete"
          disabled={userRow.isRemoved}
          color="secondary"
          onClick={() => handleDelete(userRow.id)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="Edit"
          disabled={userRow.isRemoved}
          color="secondary"
          onClick={() => setEditMode(true)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const EditRow = ({
  userRow: { email, isRemoved, id },
  handleDelete,
  setEditMode,
  handleEdit,
}) => {
  const [_email, setEmail] = useState(email);
  const [_password, setPassword] = useState("");

  const updatedUserData = ()=>{
      let response = {}
      if(email !== _email){ response.email = _email}
      if(_password) { response.pass = _password}
      return response
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          id="outlined-email"
          label="Email"
          value={_email}
          helperText="Cambia el mail actual"
          onChange={(e)=>setEmail(e.target.value)}
          margin="normal"
          variant="outlined"
        />
      </TableCell>
      <TableCell align="right">
      <TextField
          id="outlined-password"
          label="Contraseña"
          value={_password}
          helperText="Crea una nueva contraseña"
          onChange={(e)=>setPassword(e.target.value)}
          margin="normal"
          variant="outlined"
        />
      </TableCell>
      <TableCell align="right">
      {isRemoved ? "ELIMINADO" : "activo"}
      </TableCell>
      <TableCell align="right">{id}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Edit"
          disabled={isRemoved}
          color="success"
          key={"edit"}
          onClick={() =>{ setEditMode(false); handleEdit(id,updatedUserData())}}
        >
          <CheckIcon fontSize="large" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const EditedRow = (props) => {
  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return <EditRow {...props} setEditMode={setEditMode} />;
  }
  return <TableRowSimple {...props} setEditMode={setEditMode} />;
};

export default EditedRow;
