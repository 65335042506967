import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as adminUsers from "../app/modules/AdminUser/_redux/adminUsersRedux";
import * as clientUsers from "../app/modules/ClientUser/_redux/clientUserRedux";
import * as devices from "../app/modules/Devices/_redux/devicesRedux";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  adminUsers: adminUsers.reducer,
  clientUsers: clientUsers.reducer,
  devices: devices.reducer,
  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga(),adminUsers.saga()]);
}
