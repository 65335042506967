/* eslint-disable no-restricted-imports */

import React, { useEffect } from "react";
import * as clientRedux from "./_redux/clientUserRedux";
import { getAllClientUsers } from "./_redux/clientUserCrud";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import RemotePagination from "../../components/tables/RemotePagination";

const Cbutton = (cell,row) => (
  <Link
    to={{
      pathname: "/devices",
      search: `?clientId=${row.id}`,
      state: {
        fromClient: true,
      },
    }}
  >
    Dispositivos
  </Link>
);

const columns = [
  {
    dataField: "full_name",
    text: "Nombre",
  },
  {
    dataField: "email",
    text: "Correo",
  },
  {
    dataField: "phone",
    text: "Teléfono",
  },
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "button",
    isDummyField: true,
    text: "Acción",
    formatter: Cbutton,
  },
];


function ClientsTable(props) {
  const SIZE_PER_PAGE = 50
  const { clientList, totalClients, pageNumber } = useSelector((state) => state.clientUsers);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setTimeout(() => {
      fetchClientUsers(page,sizePerPage);
    }, 2000);
  };

  const fetchClientUsers = async (page) => {
    try {
      props.requestClientList();
      const rawClientUserList = await getAllClientUsers(page-1,SIZE_PER_PAGE);
      console.log(rawClientUserList);

      props.fulfillClientList(rawClientUserList.results,rawClientUserList.total,page);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClientUsers(1); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RemotePagination
      columns={columns}
      data={clientList}
      page={pageNumber}
      sizePerPage={SIZE_PER_PAGE}
      totalSize={totalClients}
      onTableChange={handleTableChange}
    />
  );
}

export default injectIntl(connect(null, clientRedux.actions)(ClientsTable));
