import axios from "axios";

export const GET_ALL_DEVICES = "backoffice/devices";

export function getAlldevices(clientId,page,pageSize) {
  return axios.get(GET_ALL_DEVICES, {
    params: {
      clientId,
      page,
      pageSize
    }
  }).then(response=>response.data);
}
