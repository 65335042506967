/* eslint-disable no-restricted-imports */

import React, { useEffect } from "react";
import * as deviceRedux from "./_redux/devicesRedux";
import { getAlldevices } from './_redux/devicesCrud';
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import { Link } from "react-router-dom";
import RemotePagination from "../../components/tables/RemotePagination";

const Cbutton = (cell, row) => (
    <Link to={{
        pathname:`/devices/${row.id}/logs`,
        state: {
            fromDevices: true,
        }
  }}>
    Mediciones
  </Link>
);

const Ccheckbox = (cellContent) => (
  <div className="checkbox disabled">
    <label>
      <input type="checkbox" checked={ cellContent } disabled />
    </label>
  </div>)

const columns = [
  {
    dataField: "macAddress",
    text: "MacAddress",
  },
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "isRemoved",
    text: "Borrado",
    formatter: Ccheckbox
  },
  {
    dataField: "qr",
    text: "QR",
    formatter: Ccheckbox
  },
  {
    dataField: "place",
    text: "Lugar",
  },
  {
    dataField: "created_at",
    text: "Creado",
  },
  {
    dataField: "updated_at",
    text: "Editado",
  },
  {
    dataField: "button",
    isDummyField: true,
    text: "Acción",
    formatter: Cbutton,
  },
];


function DevicesTable(props) {
  const SIZE_PER_PAGE = 50
  let params = new URLSearchParams(useLocation().search);
  const clientId = params.get("clientId")

  const { deviceList, totalDevices, pageNumber } = useSelector((state)=>state.devices)

  const handleTableChange = (type, { page, sizePerPage }) => {
    setTimeout(() => {
      fetchDevices(page,sizePerPage);
    }, 2000);
  };

  const fetchDevices = async (page,sizePerPage) => {
    try {
      props.requestDeviceList()
      const rawDevicesResponse = await getAlldevices(clientId,page-1,sizePerPage)
      console.log(rawDevicesResponse)
      const {results,total} = rawDevicesResponse
      props.fulfillDeviceList(results, total, page)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchDevices(1,SIZE_PER_PAGE); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <RemotePagination
      columns={columns}
      data={deviceList}
      page={pageNumber}
      sizePerPage={SIZE_PER_PAGE}
      totalSize={totalDevices}
      onTableChange={handleTableChange}
    />
  );
}

export default injectIntl(connect(null, deviceRedux.actions)(DevicesTable));
