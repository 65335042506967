import axios from "axios";

export const GET_ALL_DEVICES = "backoffice/devices/";

export function getAllDeviceLogs(deviceId,page,pageSize) {
  return axios.get(`${GET_ALL_DEVICES}${deviceId}/logs`, {
    params: {
      page,
      pageSize
    }
  }).then(response=>response.data);
}
