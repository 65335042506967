/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
// import objectPath from "object-path";
// import {useHtmlClassService} from "../../_core/MetronicLayout";
// import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import { useHistory } from "react-router-dom";

export function QuickUserToggler() {
  const {user} = useSelector(state => state.auth);
  // const uiService = useHtmlClassService();
  // const layoutProps = useMemo(() => {
  //   return {
  //     offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
  //   };
  // }, [uiService]);

  const history = useHistory();

  const logoutClick = () => {
      const toggle = document.getElementById("user_name");
      if (toggle) {
        toggle.click();
      }
      history.push("/logout");
  };
  return (<>
        {/* {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        > */}
          <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                 id="user_name">
              <>

                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hola,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {user.email}
                </span>
                {/* <span className="symbol symbol-35 symbol-light-success">                
                    <span className="symbol-label font-size-h5 font-weight-bold">{user.email}</span>
                </span> */}
                <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Cerrar sesión</button>
              </>
            </div>
          </div>
        {/* </OverlayTrigger>)} */}

        {/* {!layoutProps.offcanvas && (<UserProfileDropdown/>)} */}
      </>
  );
}
