/* eslint-disable no-restricted-imports */

import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as userAdminRedux from "./_redux/adminUsersRedux";
import { getAllAdminUsers, deleteAdminUser, editAdminUser } from './_redux/adminUsersCrud';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from "react-redux";
import { Spinner } from 'react-bootstrap';
import EditedRow from './EditedRow';

import { NotificationManager } from "react-notifications";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
  },
}));

function parseUserList(userList){
  return userList && userList.map(({id,email,isRemoved})=>{return {id,email,isRemoved}})
}

function UsersTable(props) {
  const classes = useStyles();
  const { userList } = useSelector((state)=>state.adminUsers)
  
  //Loading
  const [loading,setLoading] = useState(false)
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const fetchUsers = async () => {
    try {
      enableLoading()
      props.requestUserList()
      const rawUserList = await getAllAdminUsers()

      props.fulfillUserList(rawUserList)
    } catch (error) {
      console.error(error)
    }
    disableLoading()
  }

  const handleDelete = async (idToBeDeleted)=>{
    try {
      await deleteAdminUser(idToBeDeleted)
      props.deleteAdminUser()
    } catch (error) {
      console.error(error)
      NotificationManager.error("Error borrando el usuario")
    }
  }
  const handleEdit = async (idToBeEdited,data)=>{
    try {
      await editAdminUser(idToBeEdited,data)
      props.editAdminUser()
    } catch (error) {
      console.error(error)
      NotificationManager.error("Error editando el usuario")
    }
  }

  useEffect(() => {
    fetchUsers() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">Contraseña</TableCell>
            <TableCell align="right">Estado</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parseUserList(userList).map(userRow => (
            <EditedRow key={userRow.id} userRow={userRow} handleDelete={handleDelete} handleEdit={handleEdit}/>
          ))}
        </TableBody>
      </Table>
      {loading && <div className={classes.centered}><Spinner animation="grow" /></div>}
    </Paper>
  );
}


export default injectIntl(connect(null, userAdminRedux.actions)(UsersTable));
