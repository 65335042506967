import React from "react";
import {useSubheader} from "../../_metronic/layout";
import ClientsTable from "../modules/ClientUser/ClientsTable";

export function ClientUsersPage() {
    
  const suhbeader = useSubheader();
  suhbeader.setTitle("Administrar usuarios clientes");
  

  return <>
    <ClientsTable/>
  </>;
}
